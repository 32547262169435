<template>
  <div>
    <Header nav="profile" active="profile" />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix mb-3">
            <div class="page-title-left">
              <h5 class="page-title-heading mt-1 py-2">Edit Profile</h5>
            </div>
          </div>

          <div class="widget-list">
            <form class="form-horizontal" @submit.prevent="send">

              <div class="row">
                <div class="col-md-3 pr-3 d-none d-md-block">
                  <h6 class="box-title mb-3">Avatar</h6>
                  <p>Your avatar is displayed on your profile and next to your comments and messages.</p>
                </div>
                <div class="col-md-9">
                  <div class="widget-holder mb-3">
                    <div class="widget-bg">
                      <div class="widget-body py-3">
                        <div class="row my-3">
                          <div class="col-sm-3 col-md-2">
                            <img :src="$auth.profile.avatar" class="avatar-lg rounded-circle mb-3 mb-sm-0" />
                          </div>
                          <div class="col-sm-9 col-md-10 pt-sm-3 pt-lg-4 mt-lg-1">
                            We're using <a href="http://gravatar.com/">Gravatar</a> to display that beautiful picture of you. Update your photo there.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 pr-3 d-none d-md-block">
                  <h6 class="box-title mb-3">Account Information</h6>
                  <p>Basic profile and contact information for your account.</p>
                </div>
                <div class="col-md-9">
                  <div class="widget-holder">
                    <div class="widget-bg">
                      <div class="widget-body">

                        <div class="form-group row">
                          <label class="col-md-2 col-form-label">Full Name</label>
                          <div class="col-md-10">
                            <input type="text" v-model="form.name" class="form-control" maxlength="254" required>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-md-2 col-form-label">Email Address</label>
                          <div class="col-md-10">
                            <input type="email" v-model="form.email" class="form-control" maxlength="254" required>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-md-2 col-form-label">Username <i class="fal fa-info-circle fa-lg text-primary" v-b-tooltip.hover title="Your username will appear on your issues and comments."></i></label>
                          <div class="col-md-10">
                            <input type="text" v-model="form.username" class="form-control" maxlength="39" required>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <button type="submit" class="btn btn-primary btn-rounded my-3"><i class="fal fa-check mr-2"></i>Save Changes</button>
                </div>
              </div>

            </form>
          </div>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { TooltipPlugin } from 'bootstrap-vue';
import Header from '@/components/AccountHeader.vue';
import toast from '@/modules/toast';

Vue.use(TooltipPlugin);

export default {
  data() {
    return {
      form: {
        name: this.$auth.profile.name,
        email: this.$auth.profile.email,
        username: this.$auth.profile.username,
      },
    };
  },
  methods: {
    send() {
      this.$api.patch('/profile', this.form)
        .then(() => {
          toast.icon(this, 'success', 'fa-check');
          // update profile
          this.$auth.profile.name = this.form.name;
          this.$auth.profile.email = this.form.email;
          this.$auth.profile.username = this.form.username;
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
  },
  components: {
    Header,
  },
};
</script>
